import {Button, Grid} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Statement = () => {
    const links = [
        {text: 'Скачать форму заявления', link: "../files/important document/Заявление.docx"},
        {text: 'Скачать образец заполнения заявления', link: "../files/important document/Образец_заявление.docx"}
    ]

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>Заполните заявление</h3>
                <p>Вам понадобятся:</p>
                <ul>
                    <li>паспорт</li>
                    <li>СНИЛС</li>
                    <li>ИНН (необязательно)</li>
                    <li>сведения о банках, в которых имеются счета (вклады)</li>
                </ul>
                <p>При заполнении заявления важно указать достоверные сведения о себе и следовать установленной форме.</p>
                <p>Указание в заявлении недостоверной информации может повлечь за собой риск перевода кредитором внесудебной процедуры в судебную и неосвобождение от долгов гражданина, признанного банкротом в судебном порядке.</p>
            </Grid>
            {links.map(link => (
                <Grid item xs={12}>
                    <Button color={'error'} 
                        startIcon={<FileDownloadIcon />}
                        component={"a"}
                        target={"_blank"}
                        href={link.link}
                        color={"error"}
                        style={{textTransform: 'none'}}
                    >
                        {link.text}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )
}

export default Statement
