import {Button, Grid} from "@mui/material";


const GoMFC = () => {
    const links = [
        {text: 'Записаться на прием в «ГБУ ПК Пермский краевой МФЦ ПГМУ»', link: "https://mfc.permkrai.ru/predvaritelnaya-zapis/"},
        {text: 'Узнать адрес и график работы отделений «ГБУ ПК Пермский краевой МФЦ ПГМУ»', link: "https://mfc.permkrai.ru/informatsiya-o-filialakh/filialy-mfts/"}
    ]

    return(
        <Grid container>
            <Grid item xs={12}>
                <h3>Обратитесь в центр «Мои Документы» для подачи заявления.</h3>
                <p>Подготовьте обязательные документы:</p>
                <ul>
                    <li>паспорт</li>
                    <li>нотариальная доверенность, если Вы действуете в интересах доверителя</li>
                    <li>свидетельство о регистрации по месту пребывания (в случае если Вы зарегистрированы в Воронежской области по месту пребывания)</li>
                    <li>список кредиторов</li>
                    <li>заявление</li>
                </ul>
            </Grid>
            {links.map(link => (
                <Grid item xs={12}>
                    <Button color={'error'} 
                        component={"a"}
                        target={"_blank"}
                        href={link.link}
                        color={"error"}
                        style={{textTransform: 'none', fontWeight: "bold"}}
                    >
                        {link.text}
                    </Button>
                </Grid>
            ))}
            <Grid item xs={12}>
                <p>Если у Вас остались вопросы, обратитесь на «горячую линию» <b>+7(342) 270-11-20</b>.</p>
                <p>Если Вам необходима индивидуальная консультация, обратитесь к администратору.</p>
            </Grid>
        </Grid>
    )
}



export default GoMFC
