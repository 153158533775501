import {createBrowserRouter} from "react-router-dom";
import React from "react";
import App from "./App";


export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/igor-petuh",
        element: <p>Игорь чушпан</p>,
    },
    {
        path: "/igor-petuh",
        element: <p>Игорь чушпан</p>,
    },
]);
