import {Box, Button, Grid} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as React from "react";

const CreditList = () => {
    const links = [
        {text: 'Скачать форму списка кредиторов', link: "../files/modal file/Список_кредиторов_форм.rtf"},
        {text: 'Скачать образец заполнения списка кредиторов', link: "../files/modal file/Образец список кредиторов.docx"},
        {text: 'Скачать инструкцию по заполнению списка кредиторов и должников гражданина', link: "../files/instruction/Инструкция_по_заполнению_списка_кредиторов_и_должников_гражданина.rtf"},
        {text: 'Скачать инструкцию по получению информации о хранении кредитной истории и кредитного отчета', link: "../files/modal file/Скачать_инструкцию_по_получению_информации_о_хранении_кредитной.pdf"},
        {text: 'Скачать контакты бюро кредитных историй, внесенных в государственный реестр', link: "../files/instruction/Контакты_бюро_кредитных_историй,_внесенных_в_государственный_реестр.pdf"}
    ]

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>Подготовьте список всех известных кредиторов.</h3>
                <p>Вам потребуются:</p>
                <p>данные паспорта, СНИЛС, ИНН; следующие сведения о долгах и кредиторах:</p>
                <ul>
                    <li>содержание обязательства (банковский кредит, денежный займ, арендные платежи, алименты и т.д.)</li>
                    <li>ФИО кредитора или наименование юридического лица</li>
                    <li>место нахождения (адрес) кредитора</li>
                    <li>основания возникновения задолженности (реквизиты договоров, судебных актов, расписок, иных документов)</li>
                    <li>по каждому кредитору - сумма основного долга и размер невыплаченной задолженности по нему</li>
                    <li>штрафы, пени и т.д.</li>
                </ul>
                <p>Где можно узнать сведения для заполнения списка кредиторов:</p>
                <ul>
                    <li>В документах, оформленных при получении кредита, займа, при получении жилищно-коммунальных услуг и т. п. (кредитный договор, договор займа, расписка, договор аренды, договор о предоставлении коммунальных услуг, договор цессии и т.д.)</li>
                    <li>У кредитора. Обратитесь к кредитору, чтобы получить копию кредитного договора, справку об оставшейся задолженности, иные сведения</li>
                    <li>В бюро кредитных историй (БКИ). Через <a href="https://www.gosuslugi.ru/600311/1/form" target={"_blank"} style={{fontWeight:500}}> Госуслуги</a>  можно узнать, в каком бюро кредитных историй (БКИ) находится ваша кредитная история, а затем запросить свою кредитную историю в данном БКИ</li>
                    <li><a href="https://lkfl2.nalog.ru/" target={"_blank"} style={{fontWeight:500}}> В личном кабинете налогоплательщика</a>, в личном кабинете на портале «Госуслуги» в разделе <a href="https://www.gosuslugi.ru/pay/quittance" target={"_blank"} style={{fontWeight:500}}> «Платежи»</a> – задолженность по налогам и сборам.</li>
                </ul>
                <p>При формировании списка кредиторов важно указать всех известных кредиторов и следовать установленной форме.
                    Указание в списке кредиторов недостоверной информации может повлечь за собой риск перевода кредитором
                    внесудебной процедуры в судебную и неосвобождение от долгов гражданина, признанного банкротом в судебном порядке.
                </p>
            </Grid>
            {links.map(link => (
                <Grid item xs={12}>
                    <Button color={'error'} 
                        startIcon={<FileDownloadIcon />}
                        component={"a"}
                        target={"_blank"}
                        href={link.link}
                        color={"error"}
                        style={{textTransform: 'none'}}
                    >
                        {link.text}
                    </Button>
                </Grid>
            ))}
        </Grid>
    )
}

export default CreditList
