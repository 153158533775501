import Box from "@mui/material/Box";
import {Grid} from "@mui/material";

const Main = () => {

    return (
        <Box className={'main-content'}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2>Бесплатно</h2>
                    <p>Не нужно платить пошлину или тратить деньги на платных специалистов</p>
                </Grid>
                <Grid item xs={12}>
                    <h2>Быстро</h2>
                    <p>Освобождение от долгов по истечении 6 месяцев</p>
                </Grid>
                <Grid item xs={12}>
                    <h2>Без юриста</h2>
                    <p>Не нужны юристы, арбитражные управляющие и другие платные специалисты</p>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Main
