import Background from "../image/fotobankrot.png";
import {Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";

export const mainStyle = {
    container: {
        minHeight: '100vh'
    },
    homeH3: {
        fontSize: 30,
        color: '#ffff'
    },
    contentBackgroundImg: {
        backgroundColor: '#E9E9E9',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: "contain",
        backgroundPosition: "right"
    },
    contentContainer: {
        backgroundColor: 'rgba(233,233,233,0.87)',
        width: '100%',
        height:'100%'
    }
}

export const linkStyle = {
    textDecoration: 'none',
    color: '#d40d0d'
}


export const customStyle = {
    contentCenter: {
        display: 'flex',
        justifyContent: 'center'
    }
}
