import {Button, Grid, Paper} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {useState} from "react";
import {customStyle} from "../../assets/style/style";
import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

const DocumentBasis = (props) => {
    const {
        handleChangeStep
    } = props
    const [isNotDocument, setIsNotDocument] = useState(false)
    const [step, setStep] = useState(0)

    const renderStep = () => {
        switch (step) {
            case 0: return <Step1 setIsNotDocument={setIsNotDocument} setStep={setStep}/>
            case 1: return <Step2 setIsNotDocument={setIsNotDocument} handleChangeStep={handleChangeStep} setStep={setStep}/>
            case 2: return <Step3 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 3: return <Step4 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 4: return <Step5 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 5: return <Step6 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 6: return <Step7 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 7: return <Step8 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 8: return <Step9 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 9: return <Step10 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 10: return <Step11 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 11: return <Step12 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 12: return <Step13 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 13: return <Step14 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 14: return <Step15 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 15: return <Step16 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
            case 16: return <Step17 setIsNotDocument={setIsNotDocument} setStep={setStep} handleChangeStep={handleChangeStep}/>
        }
    }

    return (
        <Grid container>
            {
                isNotDocument ?
                    <Grid item xs={12} style={customStyle.contentCenter}>
                        <h3>Вы не можете быть признаны банкротом во внесудебном порядке.</h3>
                    </Grid>
                    : renderStep()
            }
        </Grid>
    )
}

export default DocumentBasis

const Step1 = (props) => {
    const {
        setStep,
        setIsNotDocument
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>В отношении Вас был выдан исполнительный документ (исполнительный лист, судебный приказ, нотариально удостоверенное соглашение, постановление судебного-пристава исполнителя или др. документ в соответствии со ст. 12 Закона об исполнительном производстве)?</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(1)}>
                    Да
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CloseIcon/>} style={{margin:10}} onClick={()=> setIsNotDocument(true)}>
                    Нет
                </Button>
            </Grid>
            <Grid item xs={12}>
                <p>
                    Узнать о наличии исполнительного производства в отношении любого физического лица можно в информационном сервисе
                    <a href="https://fssp.gov.ru/iss/ip" target={"_blank"} style={{fontWeight:500}}> «Банк данных исполнительных производств»</a>
                </p>
            </Grid>
            <Grid item xs={12} >
                <Button color={'error'} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/important document/Памятка по использованию Банка данных исполнительных производств.pdf'}
                    style={{textTransform: 'none'}}
                >Скачать Памятку по использованию Банка данных исполнительных производств.</Button>
            </Grid>
        </>
    )
}

const Step2 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>В информационном сервисе <a href="https://fssp.gov.ru/iss/ip" target={"_blank"} style={{fontWeight:500}}> «Банк данных исполнительных производств»</a> проверьте информацию об исполнительных производствах в отношении себя.</p>
            </Grid>
            <Grid item xs={12} >
                <Button color={"error"} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Инструкция по проверке ФССП.pdf'}
                    style={{textTransform: 'none'}}
                >Скачать Инструкцию по проверке</Button>
            </Grid>
            <Grid item xs={12}>
                <p>По результатам проверки Вы обнаружили соблюдение одновременно двух условий?</p>
                <p>а) окончено исполнительное производство в связи с отсутствием имущества (на основании пункта 4 части 1 статьи 46 Федерального закона от 2 октября 2007 года № 229-ФЗ «Об исполнительном производстве»);</p>
                <p>б) нет новых неоконченных и непрекращенных исполнительных производств, возбужденных после указанного в пункте «а».</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={"error"} variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>
                    Да
                </Button>
                <Button color={"error"} variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(2)}>
                    Нет
                </Button>
            </Grid>
        </>
    )
}


const Step3 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>

            <Grid item xs={12}>
                <p>Вы являетесь получателем:</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
               {/* <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>*/}
                    <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(6)}>
                    Ежемесячного пособия в связи с рождением и воспитанием ребенка
                </Button>
            </Grid>
                <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(10)}>
                    Пенсии (за выслугу лет, по старости, по инвалидности, по случаю потери кормильца, социальная пенсия)
                </Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(3)}>
                    Ничего из вышеперечисленного
                </Button>
            </Grid>
        </>
    )
}


const Step4 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>В отношении Вас имеется исполнительный документ, выданный 7 или более лет назад, требования по которому не исполнены или исполнены частично?</p>
                <p>Проверить информацию об исполнительных производствах можно в информационном сервисе <a href="https://fssp.gov.ru/iss/ip" target={"_blank"} style={{fontWeight:500}}> «Банк данных исполнительных производств»</a></p>
            </Grid>
            <Grid item xs={12} >
                <Button color={"error"} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Инструкция по проверке.pdf'}
                    style={{textTransform: 'none'}}
                >Скачать Инструкцию по проверке</Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={"error"} variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(4)}>
                    Да
                </Button>
                <Button color={"error"} variant={"contained"} startIcon={<CloseIcon/>} style={{margin:10}} onClick={()=> setIsNotDocument(true)}>
                    Нет
                </Button>
            </Grid>
        </>
    )
}


const Step5 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Взыскание по исполнительному документу осуществляется ФССП России или иной организацией (например, банком)?</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={"error"} variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(5)}>
                    Иной организацией
                </Button>
                <Button color={"error"} variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>
                    ФССП России
                </Button>
            </Grid>
        </>
    )
}

const Step6 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Получите справку о наличии исполнительного документа, выданного более 7 лет назад, требования по которому не исполнены или исполнены частично.</p>
                <p>За справкой обратитесь в организацию, в которую предъявлялся исполнительный документ, или осуществляющую взыскание по исполнительному документу (например, банк).</p>
                <p>Срок предоставления справки – 10 рабочих дней. Справка действительна в течение трех месяцев с даты получения.</p>
            </Grid>
            <Grid item xs={12} >
                <Button color={"error"} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Справка_исп.д-т_7_лет.doc'}
                    style={{textTransform: 'none'}}
                >Скачать форму справки</Button>
            </Grid>
            <Grid item xs={12} >
                <Button color={"error"} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/заявление_исп.д-т_7_лет.doc'}
                    style={{textTransform: 'none'}}
                >Скачать заявление о выдаче справки</Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={"error"} variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>
                    Далее
                </Button>
            </Grid>
        </>
    )
}


const Step7 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>У Вас отсутствует имущество, на которое может быть обращено взыскание?</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(7)}>
                    Да
                </Button>
                <Button color={'error'} variant={"contained"} startIcon={<CloseIcon/>} style={{margin:10}} onClick={()=> setIsNotDocument(true)}>
                    Нет
                </Button>
            </Grid>
        </>
    )
}


const Step8 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>В отношении Вас имеется исполнительный документ, выданный более 1 года назад, требования по которому не исполнены или исполнены частично?</p>
                <p>Проверить информацию об исполнительных производствах можно в информационном сервисе <a href="https://fssp.gov.ru/iss/ip" target={"_blank"} style={{fontWeight:500}}> «Банк данных исполнительных производств»</a></p>
            </Grid>
            <Grid item xs={12} >
                <Button 
                    color={"error"}
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Инструкция по проверке.pdf'}
                    style={{textTransform: 'none'}}
                >Скачать Инструкцию по проверке</Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'} variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(8)}>
                    Да
                </Button>
                <Button color={'error'} variant={"contained"} startIcon={<CloseIcon/>} style={{margin:10}} onClick={()=> setIsNotDocument(true)}>
                    Нет
                </Button>
            </Grid>
        </>
    )
}


const Step9 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Взыскание по исполнительному документу осуществляется ФССП России или иной организацией (например, банком)?</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(9)}>
                    Иной организацией
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>
                    ФССП России
                </Button>
            </Grid>
        </>
    )
}

const Step10 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Получите справку о наличии исполнительного документа, выданного более 1 года назад, требования по которому не исполнены или исполнены частично. За справкой обратитесь в организацию, в которую предъявлялся исполнительный документ, или осуществляющую взыскание по исполнительному документу (например, банк).</p>
                <p>Срок предоставления справки – 10 рабочих дней. Справка действительна в течение трех месяцев с даты получения.</p>
            </Grid>
            <Grid item xs={12} >
                <Button color={'error'} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Справка_исп.д-т-_1_год.doc'}
                    style={{textTransform: 'none'}}
                >Скачать форму справки</Button>
            </Grid>
            <Grid item xs={12} >
                <Button color={'error'} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/заявление_исп.д-т-_1_год.doc'}
                    style={{textTransform: 'none'}}
                >Скачать заявление о выдаче справки</Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>
                    Далее
                </Button>
            </Grid>
        </>
    )
}



const Step11 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>У Вас отсутствует имущество, на которое может быть обращено взыскание?</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(11)}>
                    Да
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CloseIcon/>} style={{margin:10}} onClick={()=> setIsNotDocument(true)}>
                    Нет
                </Button>
            </Grid>
        </>
    )
}



const Step12 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Пенсия назначена Социальным фондом России или иным органом (Минобороны России, МВД России, ФСБ России, ФСИН России, ФССП России, Генеральная прокуратура РФ, Следственный комитет РФ).</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(12)}>
                    Иным органом
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(15)}>
                    Социальным фондом России
                </Button>
            </Grid>
        </>
    )
}


const Step13 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Получите справку о получении Вами пенсии или срочной пенсионной выплаты.</p>
                <p>За справкой обратитесь в орган, назначивший пенсию (Минобороны России, МВД России, ФСБ России, ФСИН России, ФССП России, Генеральная прокуратура РФ, Следственный комитет РФ)</p>
                <p>Срок предоставления справки – 10 рабочих дней. Справка действительна в течение трех месяцев с даты получения.</p>
            </Grid>
            <Grid item xs={12} >
                <Button color={'error'} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Справка_пенсия.doc'}
                    style={{textTransform: 'none'}}
                >Скачать форму справки</Button>
            </Grid>
            <Grid item xs={12} >
                <Button color={'error'} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/заявление_пенсия.doc'}
                    style={{textTransform: 'none'}}
                >Скачать заявление о выдаче справки</Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(13)}>
                    Далее
                </Button>
            </Grid>
        </>
    )
}


const Step14 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>В отношении Вас имеется исполнительный документ, выданный более 1 года назад, требования по которому не исполнены или исполнены частично?</p>
                <p>Проверить информацию об исполнительных производствах можно в информационном сервисе <a href="https://fssp.gov.ru/iss/ip" target={"_blank"} style={{fontWeight:500}}> «Банк данных исполнительных производств»</a></p>
            </Grid>
            <Grid item xs={12} >
                <Button color={'error'} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Инструкция по проверке.pdf'}
                    style={{textTransform: 'none'}}
                >Скачать Инструкцию по проверке</Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(8)}>
                    Да
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CloseIcon/>} style={{margin:10}} onClick={()=> setIsNotDocument(true)}>
                    Нет
                </Button>
            </Grid>
        </>
    )
}


const Step15 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Взыскание по исполнительному документу осуществляется ФССП России, Социальным фондом России (удержание производится из пенсии) или иной организацией (например, банком)?</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(15)}>
                    Иной организацией
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>
                    ФССП России или Социальным Фондом России
                </Button>
            </Grid>
        </>
    )
}


const Step16 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>В отношении Вас имеется исполнительный документ, выданный более 1 года назад, требования по которому не исполнены или исполнены частично?</p>
                <p>Проверить информацию об исполнительных производствах можно в информационном сервисе <a href="https://fssp.gov.ru/iss/ip" target={"_blank"} style={{fontWeight:500}}> «Банк данных исполнительных производств»</a></p>
            </Grid>
            <Grid item xs={12} >
                <Button color={'error'} 
                    startIcon={<FileDownloadIcon />}
                    component={"a"}
                    target={"_blank"}
                    href={'../files/modal file/Инструкция по проверке.pdf'}
                    style={{textTransform: 'none'}}
                >Скачать Инструкцию по проверке</Button>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(16)}>
                    Да
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CloseIcon/>} style={{margin:10}} onClick={()=> setIsNotDocument(true)}>
                    Нет
                </Button>
            </Grid>
        </>
    )
}


const Step17 = (props) => {
    const {
        handleChangeStep,
        setIsNotDocument,
        setStep
    } = props

    return (
        <>
            <Grid item xs={12}>
                <p>Взыскание по исполнительному документу осуществляется ФССП России, Социальным фондом России (удержание производится из пенсии) или иной организацией (например, банком)?</p>
            </Grid>
            <Grid item xs={12} style={customStyle.contentCenter}>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> setStep(9)}>
                    Иной организацией
                </Button>
                <Button color={'error'}  variant={"contained"} startIcon={<CheckIcon/>} style={{margin:10}} onClick={()=> handleChangeStep(2)}>
                    ФССП России или Социальным Фондом России
                </Button>
            </Grid>
        </>
    )
}

