import * as React from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, IconButton,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import {useEffect, useState} from "react";
import CheckCredit from "./CheckCredit";
import DocumentBasis from "./DocumentBasis";
import Button from "@mui/material/Button";
import CreditList from "./CreditList";
import Statement from "./Statement";
import GoMFC from "./GoMFC";
import CloseIcon from '@mui/icons-material/Close';

const CalcModal = (props) => {
    const {
        openModal,
        setOpenModal
    } = props

    const [state, setState] = useState({
        currentStep: 0,
        moneyList: [null],
        sum: 0
    })

    const {
        currentStep
    } = state

    const steps = [
        {stepNum: 1, stepName: 'Задолженность'},
        {stepNum: 2, stepName: 'Основание'},
        {stepNum: 3, stepName: 'Кредиторы'},
        {stepNum: 4, stepName: 'Заявление'},
        {stepNum: 5, stepName: 'МФЦ'}
    ]

    const handleClose = () => {
        setOpenModal(false)
        setState({
            currentStep: 0,
            moneyList: [null],
            sum: 0
        })
    }

    const handleChangeStep = step => {
        setState(prev => ({
            ...prev,
            currentStep: step
        }))
    }

    const renderStepContent = num => {
        switch (num) {
            case 0: return <CheckCredit state={state} setState={setState} />
            case 1: return <DocumentBasis state={state} setState={setState} handleChangeStep={handleChangeStep}/>
            case 2: return <CreditList />
            case 3: return <Statement />
            case 4: return <GoMFC />
        }
    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleClose}
                maxWidth={'md'}
                scroll={'paper'}
            >
                <DialogTitle>
                    <Stepper activeStep={currentStep} style={{padding:10}}>
                        {steps.map((step, index) => {
                            const stepProps = {};
                            stepProps.completed = currentStep > index

                            return (
                                <Step key={index} {...stepProps} >
                                    <StepLabel>{step.stepName}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <IconButton
                        onClick={handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{minHeight:'350px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {renderStepContent(currentStep)}
                            </Grid>
                        </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color={'error'} 
                        disabled={currentStep===0}
                        variant={"outlined"}
                        color={"error"}
                        onClick={()=> {handleChangeStep(currentStep-1)}}
                        style={{float:"left"}}
                    >Назад</Button>
                    <Button color={'error'} 
                        disabled={[1,4].includes(currentStep)}
                        variant={"outlined"}
                        color={"error"}
                        onClick={()=> {handleChangeStep(currentStep+1)}}
                        style={{float:"right"}}
                    >Далее</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CalcModal
