import {linkStyle, mainStyle} from "../assets/style/style";
import {Box, Button, Grid} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';



const FormDocuments = () => {

    const links = [
        {text: 'Инструкция по заполнению списка кредиторов и должников гражданина.', link: "../files/instruction/Инструкция_по_заполнению_списка_кредиторов_и_должников_гражданина.rtf"},
        {text: 'Инструкция по получению информации об исполнительном производстве на сайте ФССП России.', link: "../files/instruction/Инструкция_по_получению_информации_об_исполнительном_производстве.pdf"},
        {text: 'Инструкция по проверке соответствия должника условиям для возбуждения процедуры внесудебного банкротства в Банке данных исполнительных производств.', link: "../files/instruction/Инструкция_по_проверке_соответствия_должника_условиям_для_возбуждения.pdf"},
        {text: 'Инструкция по получению информации о хранении кредитной истории и кредитного отчета.', link: "../files/instruction/Информация_о_кредитной_истории.pdf"},
        {text: 'Контакты бюро кредитных историй, внесенных в государственный реестр, на сайте Банка России.', link: "../files/instruction/Контакты_бюро_кредитных_историй,_внесенных_в_государственный_реестр.pdf"},
        {text: 'Проверка сведений о внесудебном банкротстве гражданина в Едином федеральном реестре сведений о банкротстве.', link: "../files/instruction/Проверка_сведений_о_внесудебном_банкротстве_гражданина_в_Едином.pdf"},
        {text: 'Информационная брошюра о внесудебном банкротстве.', link: "../files/instruction/Информационная_брошюра_о_внесудебном_банкротстве.pptx"}
    ]

    return (
        <Box className={'custom-link'} style={mainStyle.contentContainer}>
            <Box padding={4}>
                <h1>Инструкции</h1>
                <Grid container>
                    {links.map(link => (
                        <Grid item xs={12}>
                            <Button color={'error'} 
                                startIcon={<FileDownloadIcon />}
                                component={"a"}
                                target={"_blank"}
                                href={link.link}
                                color={"error"}
                            >
                                {link.text}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}



export default FormDocuments
