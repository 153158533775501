import {Grid, IconButton, Paper, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";

const CheckCredit = (props) => {
    const {
        setState,
        state: {
            moneyList,
            sum
        }
    } = props

    const addMoney = () => {
        setState(prev => ({
            ...prev,
            moneyList: [...prev.moneyList, null]
        }))
    }

    const handleChange = idx => event => {
        setState(prev => {
            prev.moneyList[idx] = parseInt(event.target.value)
            prev.sum = prev.moneyList.reduce((acc, val) => acc + val, 0)

            return {
                ...prev
            }
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>Посчитайте размер Вашей задолженности</h3>
                <p>
                    Учитывайте всю оставшуюся задолженность: как просроченные платежи, так и те, срок уплаты которых еще не наступил. Не учитывайте уже уплаченную часть долга, а также неустойки, штрафы, пени, проценты, иные санкции за просрочку платежа.
                </p>
            </Grid>
            <Grid item xs={12}>
                {moneyList.map((m, i) => (
                    <Box style={{display: "inline-block", margin: '10px 0'}}>
                        <TextField
                            label="Задолженность"
                            variant="outlined"
                            color={"error"}
                            size={"small"}
                            defaultValue={m}
                            onChange={handleChange(i)}
                            type="number"
                        />
                        <Tooltip title="Добавить задолженность">
                            <IconButton onClick={addMoney}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ))}
            </Grid>
            <Grid item xs={12}>
                <h3>Размер вашей задолженности: {sum}</h3>
            </Grid>
        </Grid>
    )
}

export default CheckCredit
