import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/index.css';
import {
    RouterProvider,
} from "react-router-dom";
import {router} from "./route";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <RouterProvider router={router}/>
)
