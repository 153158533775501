import {Box, Button, ButtonGroup, Grid, Paper, Step, StepContent, StepLabel, Stepper} from "@mui/material";
import {mainStyle} from "./assets/style/style";
import {useState} from "react";
import FormDocuments from "./component/FormDocuments";
import Main from "./component/Main";
import * as React from "react";
import CalcModal from "./component/calc/CalcModal";
import Manuals from "./component/Manual";



function App() {
    const [number, setNumber] = useState(localStorage.getItem("numberPage") || 0)
    const [openModal, setOpenModal] = useState(false)

    const page = {
        0: <Main/>,
        1: <Manuals />,
        2: <FormDocuments/>
    }

    const handleChange = (number) => {
        setNumber(number)
        localStorage.setItem("numberPage", number)
    }

    const  steps = [
           {
            description: `Пройдите анкетирование, чтобы узнать, имеются ли основания для признания Вас банкротом во внесудебном порядке, и какие документы необходимо представить`,
            fnClick: ()=> setOpenModal(!openModal)
        },
        {
            description: 'Скачайте необходимые для подачи заявления формы документов с образцами заполнения',
            fnClick: ()=> handleChange(1)
        },
        {
            description: `Ознакомьтесь с инструкцией по заполнению списка кредиторов и должников гражданина и поиску необходимой для подачи заявления информацией`,
            fnClick: ()=> handleChange(2)
        }

    ]

    return (
        <Box   style={{margin:0}}>
            <Grid container style={mainStyle.container}>
                <Grid item xs={3} style={{backgroundColor:'#DABD9A', padding: 20}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <h3 onClick={()=>handleChange(0)} style={mainStyle.homeH3}>ВНЕСУДЕБНОЕ БАНКРОТСТВО</h3>
                        </Grid>
                        <Grid item xs={12} >
                            <p> Банкротство — это процедура, которая позволяет законно освободиться от долгов, если нет возможности их выплачивать.</p>
                            <p>Причиной банкротства может стать сложная жизненная ситуация: болезнь, увольнение, инвалидность, смерть близких. Иногда единственная возможность избавиться от непосильного долгового бремени и начать с чистого листа — оформить банкротство в рамках закона.</p>
                            <p> Банкротство можно оформить через суд или во внесудебном порядке через МФЦ.</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Stepper orientation="vertical" >
                                {steps.map((step, index) => (
                                    <Step active={true} key={index} color={'error'}>
                                        <StepLabel >
                                            <Button color={'error'}  className={"text-left-start"} onClick={step.fnClick}>{step.description}</Button>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9} style={mainStyle.contentBackgroundImg}>
                    {page[number]}
                </Grid>
            </Grid>

            <CalcModal
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
        </Box>
    )
}

export default App
