import {Box, Button, Grid} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {mainStyle} from "../assets/style/style";

const Manuals = () => {
    const blocks = {
        first: {
            title: "Обязательные документы:",
            links: [
                {text: "Форма заявления о признании гражданина банкротом во внесудебном порядке.", link: "../files/important document/Заявление_банкрот.doc"},
                {text: "Образец заполнения заявления о признании гражданина банкротом во внесудебном порядке.", link: "../files/important document/Образец заявление.docx"},
                {text: "Форма списка кредиторов и должников гражданина.", link: "../files/important document/Список_кредиторов_форма.rtf"},
                {text: "Образец заполнения списка кредиторов и должников гражданина.", link: "../files/important document/Образец список кредиторов.docx"}
            ],
        },
        second: {
            title: "Справки, которые могут быть представлены по желанию гражданина:",
            links: [
                {text: "Форма заявления о выдаче справки", link: "../files/important document/заявление_исп.д-т-_1_год.doc"},
                {text: "Форма справки", link: "../files/important document/Справка_исп.д-т-_1_год.doc"}
            ],
            note: "Справка, подтверждающей, что на дату ее выдачи выданный не позднее чем за один год до даты обращения с заявлением о признании гражданина банкротом во внесудебном порядке исполнительный документ имущественного характера предъявлялся к исполнению (направлялся для исполнения) в порядке, предусмотренном законодательством Российской Федерации об исполнительном производстве, и данные требования не исполнены или исполнены частично. (Справка должна быть представлена заявителем, если исполнительный документ находится не в ФССП или СФР (например, в банке))"
        },
        three: {
            links: [
                {text: "Форма заявления о выдаче справки", link: "../files/important document/заявление_исп.д-т_7_лет.doc"},
                {text: "Форма справки", link: "../files/important document/Справка_исп.д-т_7_лет.doc"}
            ],
            note: "Справка, подтверждающая, что на дату ее выдачи выданный не позднее чем за семь лет до даты обращения с заявлением о признании гражданина банкротом во внесудебном порядке исполнительный документ имущественного характера предъявлялся к исполнению (направлялся для исполнения) в порядке, предусмотренном законодательством Российской Федерации об исполнительном производстве, и данные требования не исполнены или исполнены частично (Справка должна быть представлена заявителем, если исполнительный документ находится не в ФССП или СФР (например, в банке))"
        },
        four: {
            links: [
                {text: "Форма заявления о выдаче справки", link: "../files/important document/заявление_пособие.doc"},
                {text: "Форма справки", link: "../files/important document/Справка_пособие.doc"}
            ],
            note: "Справка, подтверждающая, что на дату ее выдачи гражданин является получателем ежемесячного пособия в связи с рождением и воспитанием ребенка в соответствии со статьей 9 Федерального закона от 19 мая 1995 г. N 81-ФЗ «О государственных пособиях гражданам, имеющим детей»."
        },
        five: {
            links: [
                {text: "Форма заявления о выдаче справки", link: "../files/important document/заявление_пенсия.doc"},
                {text: "Форма справки", link: "../files/important document/Справка_пенсия.doc"}
            ],
            note: "Справка, подтверждающая, что на дату ее выдачи гражданин является получателем страховой пенсии (с учетом фиксированной выплаты к страховой пенсии, повышений фиксированной выплаты к страховой пенсии), пенсии по государственному пенсионному обеспечению, накопительной пенсии, срочной пенсионной выплаты или пенсии, назначенной в соответствии с Законом Российской Федерации от 12 февраля 1993 г. № 4468-I «О пенсионном обеспечении лиц, проходивших военную службу, службу в органах внутренних дел, Государственной противопожарной службе, органах по контролю за оборотом наркотических средств и психотропных веществ, учреждениях и органах уголовно-исполнительной системы, войсках национальной гвардии Российской Федерации, органах принудительного исполнения Российской Федерации, и их семей» (Справка должна быть представлена заявителем, если пенсия назначена не СФР, а иным органом (например, МВД России, Минобороны России))"
        }
    }


    return (
        <Box className={'custom-link'} style={mainStyle.contentContainer}>
            <Box padding={4}>
                <h1>Формы и образцы заполнения документов</h1>
                {Object.values(blocks).map(data => (
                    <>
                        <h2>{data.title}</h2>
                        {
                            !!data.note &&
                            <p>{data.note}</p>
                        }
                        <Grid container>
                            {data.links.map(link => (
                                <Grid item xs={12}>
                                    <Button color={'error'} 
                                        startIcon={<FileDownloadIcon />}
                                        component={"a"}
                                        target={"_blank"}
                                        href={link.link}
                                        color={"error"}
                                    >
                                        {link.text}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                ))}
            </Box>
        </Box>
    )
}

export default Manuals
